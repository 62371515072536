<template>
    <div class="container-fluid">
        <div class="d-flex justify-content-center mt-3" style="margin-bottom: 60px !important">
            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                <div class="row">
                    <div class="col-6">
                        <button class="btn btn-secondary float-left" @click="printReport()"><i class="far fa-file-pdf"></i> PDF</button>
                    </div>
                    <div class="col-6">
                        <router-link to="/attendance">
                            <button class="btn btn-secondary float-right"><i class="fas fa-arrow-alt-circle-left"></i> back</button>
                        </router-link>
                    </div>
                </div>
                <div class="accordion mt-2" id="accordionExample">
                    <div class="card" v-for="item in report" :key="item.s_id">
                        <div class="card-header bg-info" id="headingOne">
                            <h2 class="mb-0">
                                <button class="btn btn-block text-left collapsed text-white" type="button" data-toggle="collapse" :data-target="'#Collapse'+item.s_id" aria-expanded="true" :aria-controls="item.s_id">
                                    <span class="pl-0">{{item.s_rumiName}}</span>
                                    <i class="fa float-right" :class="item.attendance.percent >= 60 ? 'fa-check-circle bg-success': 'fa-ban bg-danger'"></i>
                                </button>
                            </h2>
                        </div>
                        <div :id="'Collapse'+item.s_id" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                            <div class="card-body">
                                <ul>
                                    <div class="row">
                                        <div class="float-left font-weight-bold">Pensyarah : {{item.t_fnameRumi | upText}} {{item.t_lnameRumi | upText}}</div>
                                    </div>
                                    <div class="row" v-if="is_leader">
                                        <div class="float-left font-weight-bold">Telpon : {{item.t_telephone}}</div>
                                    </div>
                                    <li>
                                        <div class="float-left text-success">Hadir</div>
                                        <div class="float-right text-success">{{item.attendance.hadir}}</div>
                                    </li>
                                    <li>
                                        <div class="float-left text-danger">Ghaib</div>
                                        <div class="float-right text-danger">{{item.attendance.ghaib}}</div>
                                    </li>
                                    <li>
                                        <div class="float-left text-warning">Uzur</div>
                                        <div class="float-right text-warning">{{item.attendance.uzur}}</div>
                                    </li>
                                    <li>
                                        <div class="float-left font-weight-bold">Semua</div>
                                        <div class="float-right">{{item.attendance.total}}</div>
                                    </li>
                                    <div>
                                        <div class="float-left"></div>
                                        <div class="float-right">
                                            <router-link :to="`/attendanceDetail/${$route.params.sr_id}`">
                                                <button @click="attendanceDetail(item.rs_id)" class="btn btn-secondary btn-sm mt-3 mb-3"><i class="fa fa-arrow-circle-right"></i></button>
                                            </router-link>
                                        </div>
                                    </div>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import http from '@/services/BackendService'
    export default {
        mounted(){
            this.retreiveAttendance()
        },
        data(){
            return{
                report: [],
                is_leader: ''
            }
        },
        methods:{
            printReport(){
                window.open(`${process.env.VUE_APP_URL_COOKIE}learning/printReportAttendancePdf/${this.$route.params.sr_id}`)
            },
            attendanceDetail(s_id){
                localStorage.setItem("attendance_detail_s_id", s_id)
            },
            retreiveAttendance(){
                this.$Progress.start()
                http.get(`learning/retreiveAttendance/${this.$route.params.sr_id}`)
                    .then(response => {
                        this.report = response.data.report
                        this.is_leader = response.data.is_leader
                        this.$Progress.finish()
                    })
                    .catch(error => {
                        if(error.response.status == 401 || error.response.status == 419){
                            this.$store.dispatch("authentication/cencelAuthen")
                        }
                        this.$Progress.fail()
                    })
            }
        } 
    }
</script>

<style lang="scss" scoped>

</style>