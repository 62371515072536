import http from '@/services/BackendService'
// import router from '@/router'
import router from '@/router'

const state = {
    authStatus: localStorage.getItem('authStatus') || null
}

const getters = {
    logedIn(){
        if(state.authStatus == null){
            return false
        }else{
            if(state.authStatus == 'eyJpdiI6IlhqeWNCZjF3MWhJenRNY0pOcE1rMEE9PS'){
                return true
            }else{
                return false
            }
        }
    }
}

const mutations = {
    signIn(state, payload){
        state.authStatus = payload.passkey
    },
    signOut(state){
        state.authStatus = null
        //navigate to login page
        router.push({name: 'login'})
    }
}

const actions = {
    cencelAuthen(context){
        localStorage.removeItem('authStatus')
        context.commit('signOut')
    },
    signOut(context){
        return new Promise((resolve, reject) => {
            http.post(`logout`)
                .then(response => {
                    context.commit('signOut')
                    localStorage.removeItem('authStatus')
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                    localStorage.removeItem('authStatus')
                })
        })
    },
    signIn(context, credential){
        return new Promise((resolve, reject) => {
            let data = new FormData()
            data.append('username', credential.username)
            data.append('password', credential.password)
            http.post(`login`, credential)
                .then(response => {
                    if(response.data.status == 'success'){
                        localStorage.setItem('authStatus', response.data.passkey)
                        context.commit('signIn', {
                            passkey: response.data.passkey
                        })
                        resolve(response)
                    }else{
                        localStorage.removeItem('authStatus')
                    }
                })
                .catch(error => {
                    reject(error)
                    localStorage.removeItem('authStatus')
                })
        })
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
