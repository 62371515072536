<template>
    <div class="container-fluid">
        <div class="d-flex justify-content-center mt-3" style="margin-bottom: 60px !important">
            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                <ul class="list-group">
                    <li class="list-group-item text-center text-uppercase active">
                        semester / tahun pengajian
                    </li>
                    <li class="list-group-item" v-for="(item, index) in registered_list" :key="index">
                        <div class="d-flex justify-content-between">
                            <span>
                                {{ item.term }} / {{ item.academic_year }}
                            </span>
                            <router-link :to="{name: 'AttendanceSubject', params:{'sr_id': item.sr_id}}">
                                <button class="btn btn-secondary btn-sm">perinci <i class="fa fa-arrow-circle-right"></i></button>
                            </router-link>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    import http from '@/services/BackendService'
    export default {
        mounted(){
            this.retreiveAttendance()    
        },
        data(){
            return{
                registered_list: [],
            }
        },
        methods:{
            retreiveAttendance(){
                this.$Progress.start()
                http.get(`learning/retreiveStudentRegistered`)
                    .then(response => {
                        this.registered_list = response.data.data
                        this.$Progress.finish()
                    })
                    .catch(error => {
                        if(error.response.status == 401 || error.response.status == 419){
                            this.$store.dispatch("authentication/cencelAuthen")
                        }
                        this.$Progress.fail()
                    })
            }
        } 
    }
</script>

<style lang="scss" scoped>

</style>